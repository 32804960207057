<template>
  <div>
    <v-card>
      <div class="d-flex">
        <div>
          <v-card-title>
            {{ $t('ActivityOf') }}:
          </v-card-title>
          <v-card-subtitle><span class="font-weight-bold">{{ userdata.full_name }}</span></v-card-subtitle>
          <v-card-text>
            <v-form>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="d-flex align-center"
                >
                  <v-avatar
                    size="44"
                    color="primary"
                    rounded
                    class="elevation-1"
                  >
                    <v-icon
                      dark
                      color="white"
                      size="30"
                    >
                      {{ icons.mdiTicketPercentOutline }}
                    </v-icon>
                  </v-avatar>

                  <div class="ms-2">
                    <p class="text-xs mb-0">
                      {{ $t('Coupon') }}
                    </p>
                    <h3 class="text-xl font-weight-bold">
                      {{ info.total_coupon }}
                    </h3>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <DatePicker
                    v-model="date"
                    :label="$t('Date Start')"
                    @input="reload"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <DatePicker
                    v-model="dateEnd"
                    :label="$t('Date End')"
                    @input="reload"
                  />
                </v-col>
              </v-row>
              <!-- Cafe Manager -->
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    block
                    @click="getExport"
                  >
                    Export
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </div>
        <v-spacer></v-spacer>
        <div class="illustrator-img">
          <v-img
            :width="111"
            :src="require('@/assets/images/avatars/10.png')"
          ></v-img>
        </div>
      </div>
    </v-card>
    <v-card class="mt-4">
      <v-timeline
        v-if="items.length > 0"
        v-infinite-scroll="loadMore"
        dense
        class="timeline-custom-dense timeline-custom-dots card-content mr-3 pt-4"
        infinite-scroll-disabled="infinityScrollBusy"
      >
        <!-- Flight -->
        <v-timeline-item
          v-for="(timeline, index) in items"
          :key="timeline.created_date"
          small
          :color="colors[index % colors.length]"
        >
          <v-card
            :color="colors[index % colors.length]"
          >
            <v-card-title>
              <small class="text-subtitle-2 white--text"><strong>{{ formatTimestampToDatetime(timeline.created_date) }}</strong></small>
            </v-card-title>
            <!-- [Phiếu giảm giá 50,000 VNĐ tại Healthy Cafe] đã được sử dụng bới [Customer id] với số bill [mã bill] và mã thưởng [QR code] -->
            <v-card-text class="pt-4 pb-3">
              {{ timeline.coupon_name }} {{ $t('StaffScanCoupon') }} <strong>{{ timeline.id_user }} </strong>{{ $t('StaffBillID') }}<strong> {{ timeline.order_code }} </strong> {{ $t('StaffCouponCode') }}<strong> {{ timeline.code_coupon }} </strong>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-card-text
        v-else
        class="pb-0"
      >
        {{ $t('NoData') }}
      </v-card-text>
      <v-card-actions class="mt-4 d-flex justify-center">
        <v-btn
          color="warning"
          outlined
          block
          @click="$router.back()"
        >
          Back
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import DatePicker from '@/components/DatePicker.vue'
import CafeService from '@/services/CafeService'
import store from '@/store'
import { mdiTicketPercentOutline, mdiTrendingUp } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

export default {
  components: { DatePicker },
  setup() {
    const userdata = ref({ ...store.getters['auth/currentUser'] })
    const date = ref(new Date().toISOString().substr(0, 10))
    const dateEnd = ref(new Date().toISOString().substr(0, 10))
    const items = ref([])
    const infinityScrollBusy = ref(false)
    const info = ref({})

    let stop = false
    let page = 1
    const limit = 10

    const colors = ref(['info', 'warning', 'error', 'success'])

    const getData = async () => {
      infinityScrollBusy.value = true
      const resp = await CafeService.getStaffActivity({
        page,
        limit,
        from_date: date.value,
        to_date: dateEnd.value,
        is_cafe: 1,
        is_staff: 1,
      })

      infinityScrollBusy.value = false

      const { data } = resp.data
      if (data.length === 0) {
        stop = true
      } else {
        items.value = [...items.value, ...data]
        page += 1
        stop = false
        infinityScrollBusy.value = false
      }
    }

    const loadMore = async () => {
      if (!stop) {
        await getData()
      }
    }

    const getInfo = async () => {
      const res = await CafeService.getStaffInfo({
        from_date: date.value,
        to_date: dateEnd.value,
      })
      info.value = res.data.data
      await getData()
    }

    const reload = async () => {
      page = 1
      stop = false
      infinityScrollBusy.value = true
      items.value = []
      await getInfo()
    }

    const getExport = async () => {
      const exportRes = await CafeService.getExportUrl({
        from_date: date.value,
        to_date: dateEnd.value,
      })
      const url = window.URL.createObjectURL(new Blob([exportRes.data]))
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url

      // the filename you want
      a.download = `data_${date.value.replaceAll('-', '_')}_${dateEnd.value.replaceAll('-', '_')}.xlsx`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      console.log(exportRes.data)
    }

    onMounted(async () => {
      await getInfo()
    })

    return {
      userdata,
      date,
      dateEnd,
      items,
      infinityScrollBusy,
      colors,
      info,
      getExport,

      loadMore,
      reload,
      icons: {
        mdiTrendingUp,
        mdiTicketPercentOutline,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.illustrator-img {
  position: absolute;
  right: -7%;
  bottom: 0;
}
</style>
